<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="headquarterData === undefined">
      <h4 class="alert-heading">Errore dati Sede</h4>
      <div class="alert-body">
        Nessuna Sede trovata con questo ID. Controlla
        <b-link class="alert-link" :to="{ name: 'apps-headquarters-list' }"> Lista Sedi </b-link>
        per altre Sedi.
      </div>
    </b-alert>

    <template v-if="headquarterData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <headquarters-view-headquarter-info-card :headquarter-data="headquarterData" />
        </b-col>
        <b-col class="mb-2" cols="12">
          <headquarterusers-list />
        </b-col>
        <b-col cols="12">
          <events-list />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue'
import headquartersStoreModule from '../headquartersStoreModule'
import HeadquarterusersList from '@/views/apps/headquarterusers/headquarterusers-list/HeadquarterusersList.vue'
import HeadquartersViewHeadquarterInfoCard from './HeadquartersViewHeadquarterInfoCard.vue'
import EventsList from '@/views/apps/events/events-list/EventsList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    HeadquarterusersList,
    EventsList,
    HeadquartersViewHeadquarterInfoCard,
  },
  setup() {
    const toast = useToast()
    const headquarterData = ref(null)

    const HEADQUARTERS_APP_STORE_MODULE_NAME = 'app-headquarters'

    // Register module
    if (!store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(HEADQUARTERS_APP_STORE_MODULE_NAME, headquartersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(HEADQUARTERS_APP_STORE_MODULE_NAME)
      }
    })

    store
      .dispatch('app-headquarters/fetchHeadquarter', { id: router.currentRoute.params.id })
      .then((response) => {
        headquarterData.value = response.data
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      headquarterData,
    }
  },
}
</script>

<style></style>
