<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row class="pt-2">
          <b-col class="mb-4 d-flex justify-content-center align-items-center" cols="12">
            <feather-icon icon="CalendarIcon" size="25" class="mr-2" />
            <h1>Eventi</h1>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Cerca..." />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refEventsListTable"
        class="position-relative"
        :items="fetchEvents"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Evento corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top">#{{ data.item.id }}</span>
          </div>
        </template>

        <template #cell(nome)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.name }}</span>
          </div>
        </template>

        <template #cell(inizio)="data">
          <div class="text-nowrap">
            <feather-icon class="mr-1 text-primary" icon="CalendarIcon" size="18" />
            <span class="align-text-top">{{ parseDate(data.item.start_day, data.item.start_time) }}</span>
          </div>
        </template>

        <template #cell(fine)="data">
          <div class="text-nowrap">
            <feather-icon class="mr-1 text-primary" icon="CalendarIcon" size="18" />
            <span class="align-text-top">{{ parseDate(data.item.end_day, data.item.end_time) }}</span>
          </div>
        </template>

        <template #cell(tipo)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveTypeIcon(data.item.type)"
              size="18"
              class="mr-50"
              :class="`text-${resolveTypeVariant(data.item.type)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveTypeName(data.item.type) }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted"
              >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEvents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useEventsList from './useEventsList'
import eventsStoreModule from '../eventsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,
    BAvatar,

    vSelect,
  },
  setup() {
    const EVENTS_APP_STORE_MODULE_NAME = 'app-events'

    // Register module
    if (!store.hasModule(EVENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(EVENTS_APP_STORE_MODULE_NAME, eventsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENTS_APP_STORE_MODULE_NAME)
    })

    const types = [
      { value: 'generic', label: 'Generale' },
      { value: 'meeting', label: 'Riunioni' },
      { value: 'vehicle', label: 'Veicoli' },
      { value: 'justification', label: 'Giustificativi' },
    ]

    const {
      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventsListTable,
      refetchData,

      parseDate,
      resolveTypeVariant,
      resolveTypeIcon,
      resolveTypeName,
    } = useEventsList()

    return {
      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventsListTable,
      refetchData,

      parseDate,
      resolveTypeVariant,
      resolveTypeIcon,
      resolveTypeName,

      types,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
