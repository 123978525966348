<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row class="pt-2">
          <b-col
            class="mb-4 d-flex justify-content-center align-items-center"
            cols="12"
          >
            <feather-icon
              icon="UserIcon"
              size="25"
              class="mr-2"
            />
            <h1>Utenti Sede</h1>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <headquarterusers-list-filters
          :role-filter.sync="roleFilter"
          :role-options="roleOptions"
        />
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refHeadquarterusersListTable"
        class="position-relative"
        :items="fetchHeadquarterusers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Utente corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.anagrafico_id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.avatar_file ? mediaUrl + data.item.avatar_file : ''"
                :text="avatarText(data.item.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.display_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.identifier }}</small>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.email }}</span>
          </div>
        </template>

        <!-- Column: Telephone -->
        <template #cell(telefono)="data">
          <div class="text-nowrap">
            <feather-icon
              class="mr-1"
              :class="data.item.phone_number ? 'text-success' : 'text-danger'"
              :icon="data.item.phone_number ? 'PhoneIcon' : 'PhoneOffIcon'"
            />
            <span class="align-text-top">{{ data.item.phone_number }}</span>
          </div>
        </template>

        <template #cell(ruolo)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role_code)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role_code)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveUserRoleName(data.item.role_code) }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalHeadquarterusers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import HeadquarterusersListFilters from './HeadquarterusersListFilters.vue'
import useHeadquarterusersList from './useHeadquarterusersList'
import headquarterusersStoreModule from '../headquarterusersStoreModule'

export default {
  components: {
    HeadquarterusersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,
    BAvatar,

    vSelect,
  },
  setup() {
    const HEADQUARTERUSERS_APP_STORE_MODULE_NAME = 'app-headquarterusers'

    // Register module
    if (!store.hasModule(HEADQUARTERUSERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(HEADQUARTERUSERS_APP_STORE_MODULE_NAME, headquarterusersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HEADQUARTERUSERS_APP_STORE_MODULE_NAME)) { store.unregisterModule(HEADQUARTERUSERS_APP_STORE_MODULE_NAME) }
    })

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'HR', value: 'hr' },
      { label: 'Reception HUB', value: 'reception_hub' },
      { label: 'Reception COLLE', value: 'reception_colle' },
      { label: 'Contabilità', value: 'accountant' },
      { label: 'Utente', value: 'user' },
    ]

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const {
      fetchHeadquarterusers,
      tableColumns,
      perPage,
      currentPage,
      totalHeadquarterusers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHeadquarterusersListTable,
      refetchData,

      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      // Extra Filters
      roleFilter,
    } = useHeadquarterusersList()

    return {
      fetchHeadquarterusers,
      tableColumns,
      perPage,
      currentPage,
      totalHeadquarterusers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHeadquarterusersListTable,
      refetchData,

      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      // Filter
      avatarText,

      roleOptions,
      mediaUrl,

      // Extra Filters
      roleFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
