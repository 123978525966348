import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useEventsList() {
  // Use toast
  const toast = useToast()

  const refEventsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'inizio', sortable: true },
    { key: 'fine', sortable: true },
    { key: 'tipo', sortable: true },
  ]
  const fields = {
    id: 'id',
    nome: 'name',
    inizio: 'start_day',
    fine: 'end_day',
    tipo: 'type',
  }
  const perPage = ref(10)
  const totalEvents = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('fine')
  const isSortDirDesc = ref(true)
  const user_id = router.currentRoute.name === 'apps-users-view' ? router.currentRoute.params.id : null
  let vehicle_id = null
  if (
    router.currentRoute.name === 'apps-vehicles-view'
    || router.currentRoute.name === 'apps-vehiclesreception-view'
  ) {
    vehicle_id = router.currentRoute.params.id
  }
  const headquarter_id = router.currentRoute.name === 'apps-headquarters-view' ? router.currentRoute.params.id : null
  let meeting_room_id = null
  if (
    router.currentRoute.name === 'apps-meetingrooms-view'
    || router.currentRoute.name === 'apps-meetingroomsreception-view'
  ) {
    meeting_room_id = router.currentRoute.params.id
  }

  const dataMeta = computed(() => {
    const localItemsCount = refEventsListTable.value ? refEventsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEvents.value,
    }
  })

  const refetchData = () => {
    refEventsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchEvents = (ctx, callback) => {
    store
      .dispatch('app-events/fetchEvents', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        user_id,
        vehicle_id,
        headquarter_id,
        meeting_room_id,
      })
      .then(response => {
        const events = response.data[0]
        const total = response.data[1]

        callback(events)
        totalEvents.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = (date, time) => {
    if (time) {
      const fullDate = `${date} ${time}`
      return moment(fullDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
    }
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
  }

  const resolveTypeVariant = type => {
    if (type === 'generic') return 'success'
    if (type === 'vehicle') return 'warning'
    if (type === 'meeting') return 'danger'
    if (type === 'justification') return 'info'
    return 'primary'
  }

  const resolveTypeIcon = type => {
    if (type === 'generic') return 'GlobeIcon'
    if (type === 'vehicle') return 'TruckIcon'
    if (type === 'meeting') return 'MonitorIcon'
    if (type === 'justification') return 'ClipboardIcon'
    return 'GlobeIcon'
  }

  const resolveTypeName = type => {
    if (type === 'generic') return 'Generale'
    if (type === 'vehicle') return 'Veicoli'
    if (type === 'meeting') return 'Riunioni'
    if (type === 'justification') return 'Giustificativi'
    return 'Generale'
  }

  return {
    fetchEvents,
    tableColumns,
    perPage,
    currentPage,
    totalEvents,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEventsListTable,

    parseDate,
    resolveTypeVariant,
    resolveTypeIcon,
    resolveTypeName,

    refetchData,
  }
}
