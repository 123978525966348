<template>
  <div>
    <b-form-input
      v-if="edit"
      class="mb-2"
      ref="addressInput"
      v-model="address"
      hide-details="auto"
      placeholder="Cerca indirizzo"
    />
    <div class="mt-2 map-container" ref="map" />
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
const defaultCoordinates = { lat: 41.89193, lng: 12.51133 }
const defaultZoom = 12

export default {
  components: {
    BFormInput,
  },
  props: {
    value: Object,
    edit: Boolean,
  },
  data() {
    return {
      address_components: null,
      formatted_address: null,
      address: null,
      coordinates: Object.assign({}, defaultCoordinates),
      zoom: defaultZoom,
      map: null,
      marker: null,
    }
  },
  mounted() {
    this.valueUpdated()
    if (this.googleMapReady) {
      this.initGoogleMap()
    }
  },
  beforeDestroy() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete)
    }
    this.autocomplete = null
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map)
    }
    this.map = null

    if (this.marker) {
      google.maps.event.clearInstanceListeners(this.marker)
    }
    this.marker = null
  },
  computed: {
    googleMapReady() {
      return this.$googleMapPlugin.ready
    },
    result() {
      if (!this.address) return null
      return {
        address_components: this.address_components,
        formatted_address: this.formatted_address,
        address: this.address,
        coordinates: this.coordinates,
        zoom: this.zoom,
      }
    },
  },
  watch: {
    googleMapReady() {
      if (this.googleMapReady) {
        this.initGoogleMap()
      }
    },
    value() {
      if (JSON.stringify(this.value) != JSON.stringify(this.result)) {
        this.valueUpdated()
      }
    },
    result() {
      this.$emit('input', this.result)
    },
  },
  methods: {
    valueUpdated() {
      if (this.value) {
        this.zoom = this.value.zoom || defaultZoom
        this.coordinates = Object.assign({}, this.value.coordinates || defaultCoordinates)
        this.address = this.value.address
        this.formatted_address = this.value.formatted_address
        this.address_components = this.value.address_components
      } else {
        this.zoom = defaultZoom
        this.coordinates = Object.assign({}, defaultCoordinates)
        this.address = null
        this.formatted_address = null
        this.address_components = null
      }
      this.updateMapMarkerLocation()
    },
    updateMapMarkerLocation() {
      if (this.map) {
        this.map.setCenter(this.coordinates)
      }
      if (this.marker) {
        this.marker.setPosition(this.coordinates)
      }
    },
    initGoogleMap() {
      if (this.map) return
      if (this.edit) {
        this.autocomplete = new google.maps.places.Autocomplete(this.$refs.addressInput.$refs.input, {
          fields: ['address_components', 'geometry', 'formatted_address'],
        })
        this.autocomplete.addListener('place_changed', this.addressPlaceChanged.bind(this))
      }

      this.map = new google.maps.Map(this.$refs.map, {
        center: this.coordinates,
        zoom: this.zoom,
      })
      this.marker = new google.maps.Marker({
        draggable: this.edit ? true : false,
        position: this.coordinates,
        map: this.map,
      })
      this.map.addListener(
        'zoom_changed',
        function () {
          this.zoom = this.map.getZoom()
        }.bind(this)
      )
      this.marker.addListener(
        'position_changed',
        function () {
          this.coordinates = {
            lat: this.marker.getPosition().lat(),
            lng: this.marker.getPosition().lng(),
          }
        }.bind(this)
      )
    },
    addressPlaceChanged() {
      const place = this.autocomplete.getPlace()
      if (place && place.geometry && place.geometry.location) {
        this.coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.updateMapMarkerLocation()
      }
      if (place && place.formatted_address) {
        this.formatted_address = place.formatted_address
      }
      if (place && place.address_components) {
        this.address_components = place.address_components
      }
      this.address = this.$refs.addressInput.$refs.input.value
    },
  },
}
</script>

<style>
.map-container {
  height: 350px;
}
</style>
